import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

import Categories from 'components/blog-container'

const Blog = ({ data }) => <Categories data={data} cult='ru' />

Blog.propTypes = { data: PropTypes.any }

export default Blog

export const pageQuery = graphql`
  {
    categories: allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fileAbsolutePath: { regex: "/posts/" } }
    ) {
      totalCount
      edges {
        node {
          excerpt
          frontmatter {
            title
            path
            tags
            lang
            description
            image {
              childImageSharp {
                resize(width: 250, height: 250) {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`
